import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Course } from '../../models/course.model';
import { VideoGuide } from '../../models/video-guide.model';
import * as coursesActions from '../actions/courses.actions';
import * as persistActions from '../actions/persist.actions';

const selectId = (entity: any) => entity?._id;

export const featuredCoursesAdapter: EntityAdapter<Course> = createEntityAdapter<Course>({
    selectId
});
export const coursesAdapter: EntityAdapter<Course> = createEntityAdapter<Course>({
    selectId
});
export const enrolledCoursesAdapter: EntityAdapter<Course> = createEntityAdapter<Course>({
    selectId
});
export const extendedCoursesAdapter: EntityAdapter<Course> = createEntityAdapter<Course>({
    selectId
});

export interface CoursesState {
    featuredCourses: EntityState<Course>;
    courses: EntityState<Course>;
    enrolledCourses: EntityState<Course>;
    extendedCourses: EntityState<Course>;
    currentCourse: Course | null;
    currentCourseNextGuide: VideoGuide | null;
    masterCourse: Course | null;
    coursesCounters:
        | {
              category: string;
              count: number;
          }[]
        | [];
}

export const coursesInitialState: CoursesState = {
    featuredCourses: featuredCoursesAdapter.getInitialState(),
    courses: coursesAdapter.getInitialState(),
    enrolledCourses: enrolledCoursesAdapter.getInitialState(),
    extendedCourses: extendedCoursesAdapter.getInitialState(),
    currentCourse: null,
    currentCourseNextGuide: null,
    masterCourse: null,
    coursesCounters: []
};

export const coursesReducer = createReducer(
    coursesInitialState,
    on(persistActions.resetDashboardContent, state => ({
        ...state,
        enrolledCourses: coursesInitialState.enrolledCourses,
        featuredCourses: coursesInitialState.enrolledCourses,
        courses: coursesInitialState.courses,
        currentCourse: coursesInitialState.currentCourse,
        coursesCounters: coursesInitialState.coursesCounters
    })),
    on(coursesActions.loadFeaturedCoursesSuccess, (state, { payload }) => ({
        ...state,
        courses: coursesAdapter.addMany(payload, state.courses)
    })),
    on(coursesActions.loadCoursesSuccess, (state, { payload }) => ({
        ...state,
        courses: coursesAdapter.addMany(payload.courses, state.courses),
        coursesCounters: [...payload.counters]
    })),
    on(coursesActions.loadEnrolledCoursesSuccess, (state, { payload }) => ({
        ...state,
        enrolledCourses: enrolledCoursesAdapter.setAll(payload.courses, state.enrolledCourses),
        currentCourse: payload.currentCourse
        // currentCourseNextGuide: payload.nextVideoGuide
    })),
    on(coursesActions.loadCourseSuccess, (state, { payload }) => ({
        ...state,
        extendedCourses: extendedCoursesAdapter.setOne(payload, state.extendedCourses)
    })),
    on(coursesActions.loadMasterCourseSuccess, (state, { payload }) => ({
        ...state,
        masterCourse: payload.masterCourse
    }))
);

