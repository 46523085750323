import { createReducer, createSelector, MemoizedSelector, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import * as metaActions from '../../store/actions/meta.actions';
import { EnrolledCourse } from '../../models/enrolled-course.model';
import { ClappedVideoGuidesMap, ProgressVideosMap } from '../../models/video-guide.model';
import { SeenVideo } from '../../models/seen-guide.model';
import { PlayerSubscription } from '../../models/player-subscription.model';
import { RatedComment } from '../../models/rated-comment.model';
import { CoursesProgress } from '../../models/courses-progress.model';

const selectId = (entity: any) => entity?._id;

export const seenVideosAdapter: EntityAdapter<SeenVideo> = createEntityAdapter<SeenVideo>({
    selectId
});
export const playersSubscriptionsAdapter: EntityAdapter<PlayerSubscription> = createEntityAdapter<
    PlayerSubscription
>({
    selectId
});
export const likedCommentsAdapter: EntityAdapter<RatedComment> = createEntityAdapter<RatedComment>({
    selectId: (entity: any) => entity?.commentId
});
export const dislikedCommentsAdapter: EntityAdapter<RatedComment> = createEntityAdapter<
    RatedComment
>({
    selectId: (entity: any) => entity?.commentId
});
export const enrolledCoursesAdapter: EntityAdapter<EnrolledCourse> = createEntityAdapter<
    EnrolledCourse
>({
    selectId
});

export interface MetaState {
    seenVideos: EntityState<SeenVideo>;
    playersSubscriptions: EntityState<PlayerSubscription>;
    likedComments: EntityState<RatedComment>;
    dislikedComments: EntityState<RatedComment>;
    enrolledCourses: EntityState<EnrolledCourse>;
    clappedVideoGuides: ClappedVideoGuidesMap;
    progressVideos: ProgressVideosMap;
    coursesProgress: CoursesProgress;
}

export const metaInitialState: MetaState = {
    seenVideos: seenVideosAdapter.getInitialState(),
    playersSubscriptions: playersSubscriptionsAdapter.getInitialState(),
    likedComments: likedCommentsAdapter.getInitialState(),
    dislikedComments: dislikedCommentsAdapter.getInitialState(),
    enrolledCourses: enrolledCoursesAdapter.getInitialState(),
    clappedVideoGuides: {},
    progressVideos: {},
    coursesProgress: {}
};
export const metaReducer = createReducer(
    metaInitialState,
    on(metaActions.loadMetaDataDashboardSuccess, (state, { payload }) => ({
        ...state,
        seenVideos: seenVideosAdapter.setAll(payload.seenVideos, state.seenVideos),
        playersSubscriptions: playersSubscriptionsAdapter.setAll(
            payload.playersSubscriptions,
            state.playersSubscriptions
        ),
        likedComments: likedCommentsAdapter.setAll(payload.likedComments, state.likedComments),
        dislikedComments: dislikedCommentsAdapter.setAll(
            payload.dislikedComments,
            state.dislikedComments
        ),
        enrolledCourses: enrolledCoursesAdapter.setAll(
            payload.enrolledCourses,
            state.enrolledCourses
        ),
        clappedVideoGuides: payload.clappedVideoGuides,
        progressVideos: payload.progressVideos,
        coursesProgress: payload.coursesProgress
    })),
    on(metaActions.setVideoSeenSuccess, (state, { payload }) => ({
        ...state,
        seenVideos: seenVideosAdapter.addOne(payload, state.seenVideos)
    })),
    on(metaActions.setVideoUnseenSuccess, (state, { payload }) => ({
        ...state,
        seenVideos: seenVideosAdapter.removeOne(payload._id, state.seenVideos)
    })),
    on(metaActions.setVideoProgressSuccess, (state, { payload }) => ({
        ...state,
        progressVideos: {
            ...state.progressVideos,
            ...payload
        }
    })),
    on(metaActions.updateCourseEnrollmentSuccess, (state, { payload }) => ({
        ...state,
        enrolledCourses: enrolledCoursesAdapter.addOne(payload, state.enrolledCourses)
    })),
    on(metaActions.likeComment, (state, { payload }) => ({
        ...state,
        likedComments: likedCommentsAdapter.addOne(payload as RatedComment, state.likedComments),
        dislikedComments: dislikedCommentsAdapter.removeOne(
            payload?.commentId,
            state.dislikedComments
        )
    })),
    on(metaActions.likeCommentSuccess, (state, { payload }) => ({
        ...state,
        likedComments: likedCommentsAdapter.upsertOne(payload.ratedComment, state.likedComments)
    })),
    on(metaActions.likeCommentFailure, (state, { payload }) => ({
        ...state,
        likedComments: likedCommentsAdapter.removeOne(payload?.commentId, state.likedComments)
    })),
    on(metaActions.dislikeComment, (state, { payload }) => ({
        ...state,
        dislikedComments: dislikedCommentsAdapter.addOne(
            payload as RatedComment,
            state.dislikedComments
        ),
        likedComments: likedCommentsAdapter.removeOne(payload?.commentId, state.likedComments)
    })),
    on(metaActions.dislikeCommentSuccess, (state, { payload }) => ({
        ...state,
        dislikedComments: dislikedCommentsAdapter.upsertOne(
            payload.ratedComment,
            state.dislikedComments
        )
    })),
    on(metaActions.dislikeCommentFailure, (state, { payload }) => ({
        ...state,
        dislikedComments: dislikedCommentsAdapter.removeOne(
            payload?.commentId,
            state.dislikedComments
        )
    })),
    on(metaActions.deleteRatedCommentSuccess, (state, { payload }) => ({
        ...state,
        dislikedComments: dislikedCommentsAdapter.removeOne(
            payload?.ratedComment.commentId,
            state.dislikedComments
        ),
        likedComments: likedCommentsAdapter.removeOne(
            payload?.ratedComment.commentId,
            state.likedComments
        )
    }))
);

// export function initMetaStateMap(MetaActions: MetaActionTypes) {
//     return {
//         [MetaActions.VIDEO_GUIDE_SEEN_SET_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 seenVideos: [...state.seenVideos, action.payload]
//             };
//         },
//         [MetaActions.VIDEO_GUIDE_UNSEEN_SET_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 seenVideos: state.seenVideos.filter(seenGuide => seenGuide._id !== action.payload._id)
//             };
//         },
//         [MetaActions.USER_COURSE_ENROLLMENT_UPDATE_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 enrolledCourses: [...state.enrolledCourses, action.payload]
//             };
//         },
//         [MetaActions.PLAYER_SUBSCRIBE_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 playersSubscriptions: [...state.playerSubscriptions, action.payload]
//             };
//         },
//         [MetaActions.PLAYER_UNSUBSCRIBE_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 playersSubscriptions: state.playerSubscriptions.filter(playerSubscription => playerSubscription._id !== action.payload._id)
//             };
//         },

//         [MetaActions.VIDEO_GUIDE_CLAP_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 clappedVideoGuides: {...state.clappedVideoGuides, ...action.payload}
//             };
//         },

//         [MetaActions.COMMENT_LIKE]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 likedComments: [...state.likedComments, {commentId: action.payload.commentId} as RatedComment],
//                 dislikedComments: state.dislikedComments.filter(ratedComment => ratedComment.commentId !== action.payload.commentId)
//             };
//         },

//         [MetaActions.RATED_COMMENT_DELETE]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 likedComments: action.payload.isLike
//                     ? state.likedComments.filter(comment => comment._id !== action.payload.ratedCommentId)
//                     : state.likedComments,
//                 dislikedComments: action.payload.isLike
//                     ? state.dislikedComments
//                     : state.likedComments.filter(comment => comment._id !== action.payload.ratedCommentId)
//             };
//         },
//         [MetaActions.COMMENT_DISLIKE]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 dislikedComments: [...state.dislikedComments, {commentId: action.payload.commentId} as RatedComment],
//                 likedComments: state.likedComments.filter(ratedComment => ratedComment.commentId !== action.payload.commentId)
//             };
//         },
//         [MetaActions.COMMENT_LIKE_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 likedComments: state.likedComments.map(ratedComment => (ratedComment.commentId === action.payload.commentId
//                         ? {...ratedComment, ...action.payload}
//                         : ratedComment
//                 ))
//             };
//         },
//         [MetaActions.COMMENT_DISLIKE_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 dislikedComments: state.dislikedComments.map(ratedComment => (ratedComment.commentId === action.payload.commentId
//                         ? {...ratedComment, ...action.payload}
//                         : ratedComment
//                 ))
//             };
//         },
//         [MetaActions.META_DATA_GET_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 clappedVideoGuides: action.payload.clappedVideoGuides,
//                 likedComments: action.payload.likedComments,
//                 dislikedComments: action.payload.dislikedComments,
//                 seenVideos: action.payload.seenVideos,
//                 playersSubscriptions: action.payload.playersSubscriptions,
//                 enrolledCourses: action.payload.enrolledCourses,
//                 progressVideos: action.payload.progressVideos,
//                 coursesProgress: action.payload.coursesProgress
//             };
//         },
//         [MetaActions.VIDEO_PROGRESS_SET_SUCCESS]: (state: MetaState, action: PayloadAction): MetaState => {
//             return {
//                 ...state,
//                 progressVideos: {...state.progressVideos, ...action.payload}
//             };
//         },
//         [MetaActions.COURSES_PROGRESS_GET_SUCCESS]: (state: MetaState, action: PayloadAction) => {
//             return {
//                 ...state,
//                 coursesProgress: {...state.coursesProgress, ...action.payload}
//             };
//         }
//     };
// }

// export interface MetaStateGetters {
//     getSeenVideoGuides(state: GlobalState): any;

//     getEnrolledCourses(state: GlobalState): any;

//     getProgressVideos(state: GlobalState): any;

//     getCoursesProgress(state: GlobalState): any;

//     getPlayerSubscriptions(state: GlobalState): any;

//     getLikedComments(state: GlobalState): any;

//     getDislikedComments(state: GlobalState): any;
// }

// export interface MetaStateSelectors {
//     seenVideosSelector: MemoizedSelector<GlobalState, any>;
//     enrolledCoursesSelector: MemoizedSelector<GlobalState, any>;
//     progressVideosSelector: MemoizedSelector<GlobalState, any>;
//     coursesProgressSelector: MemoizedSelector<GlobalState, any>;
//     seenVideosMapSelector: MemoizedSelector<GlobalState, any>;
//     playersSubscriptionMapSelector: MemoizedSelector<GlobalState, any>;
//     ratedCommentsMapSelector: MemoizedSelector<GlobalState, any>;
// }

// export function initMetaStateGetters(game: Game): MetaStateGetters {
//     return {
//         getSeenVideoGuides: (state: GlobalState) => state[game].meta.seenVideos,
//         getEnrolledCourses: (state: GlobalState) => state[game].meta.enrolledCourses,
//         getProgressVideos: (state: GlobalState) => state[game].meta.progressVideos,
//         getCoursesProgress: (state: GlobalState) => state[game].meta.coursesProgress,
//         getPlayerSubscriptions: (state: GlobalState) => state[game].meta.playersSubscriptions,
//         getLikedComments: (state: GlobalState) => state[game].meta.likedComments,
//         getDislikedComments: (state: GlobalState) => state[game].meta.dislikedComments
//     };
// }

// export function initMetaStateSelectors(stateGetters: MetaStateGetters): MetaStateSelectors {
//     const seenVideosSelector = createSelector(
//         [stateGetters.getSeenVideoGuides],
//         seenVideoGuides => seenVideoGuides
//     );
//     const getRatedComments = createSelector(
//         [stateGetters.getLikedComments, stateGetters.getDislikedComments],
//         (likedComments, dislikedComments) => [...likedComments, ...dislikedComments]
//     );
//     const playerSubscriptionsSelector = createSelector(
//         [stateGetters.getPlayerSubscriptions],
//         playerSubscriptions => playerSubscriptions
//     );
//     const seenVideosMapSelector = createSelector([seenVideosSelector], seenVideoGuides => {
//         return seenVideoGuides.reduce((result, seenVideoGuide) => {
//             result[seenVideoGuide.videoId] = seenVideoGuide;

//             return result;
//         }, {});
//     });
//     const ratedCommentsMapSelector = createSelector([getRatedComments], ratedComments => {
//         return ratedComments.reduce((result, ratedComment) => {
//             result[ratedComment.commentId] = ratedComment;

//             return result;
//         }, {});
//     });
//     const playersSubscriptionMapSelector = createSelector(
//         [playerSubscriptionsSelector],
//         playerSubscriptions => {
//             return playerSubscriptions.reduce((result, playerSubscription) => {
//                 result[playerSubscription.playerId] = playerSubscription;

//                 return result;
//             }, {});
//         }
//     );
//     const enrolledCoursesSelector = createSelector(
//         [stateGetters.getEnrolledCourses],
//         enrolledCourses => enrolledCourses
//     );
//     const progressVideosSelector = createSelector(
//         [stateGetters.getProgressVideos],
//         videoGuidesProgress => videoGuidesProgress
//     );
//     const coursesProgressSelector = createSelector(
//         [stateGetters.getCoursesProgress],
//         coursesProgress => coursesProgress
//     );

//     return {
//         seenVideosSelector,
//         enrolledCoursesSelector,
//         progressVideosSelector,
//         coursesProgressSelector,
//         seenVideosMapSelector,
//         playersSubscriptionMapSelector,
//         ratedCommentsMapSelector
//     };
// }
