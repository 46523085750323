export enum GiveawayType {
    COURSES = 'courses'
}

export const GiveawayTypeDisplayName = {
    [GiveawayType.COURSES]: 'Free Courses'
};

export const GiveawayTypes = Object.values(GiveawayType);

export interface GiveawayRedeemableItem {
    _id: string;
    name: string;
    itemId: string;
    createdAt: Date;
    updatedAt: Date;
    item?: any;
}

export interface Giveaway {
    _id: string;
    name: string;
    content: string | null;
    targetedSubscriptionStatuses: string[];
    targetedUserRoles: string[];
    type: GiveawayType;
    redeemableItemsLimit: number;
    itemLimited: boolean;
    redeemableItems: GiveawayRedeemableItem[];
    redeemers: string[];
    enabled: boolean;
    showAnnouncement: boolean;
    createdAt: Date;
    updatedAt: Date;
    startDate?: Date;
    endDate?: Date;
    thumbnailPath: string;
    isRedeemable: boolean;
}

