import { createAction, props } from '@ngrx/store';
import { Course } from '../../models/course.model';
import { APIError } from '../../models/error.model';
import { VideoGuide } from '../../models/video-guide.model';

export enum CoursesActionsTypes {
    LoadFeaturedCourses = '[Dashboard Courses] Load Featured Courses',
    LoadFeaturedCoursesSuccess = '[Content API] Load Featured Courses Success',
    LoadFeaturedCoursesFailure = '[Content API] Load Featured Courses Failure',

    LoadCourses = '[Dashboard Courses] Load Courses',
    LoadCoursesSuccess = '[Content API] Load Courses Success',
    LoadCoursesFailure = '[Content API] Load Courses Failure',

    LoadEnrolledCourses = '[Dashboard Home] Load Enrolled Courses',
    LoadEnrolledCoursesSuccess = '[Content API] Load Enrolled Courses Success',
    LoadEnrolledCoursesFailure = '[Content API] Load Enrolled Courses Failure',

    LoadCourse = '[Dashboard Course] Load Course',
    LoadCourseById = '[Dashboard Course] Load Course By ID',
    LoadCourseSuccess = '[Content API] Load Course Success',
    LoadCourseFailure = '[Content API] Load Course Failure',

    LoadMasterCourse = '[Dashboard Course] Load Master Course',
    LoadMasterCourseSuccess = '[Content API] Load Master Course Success',
    LoadMasterCourseFailure = '[Content API] Load Master Course Failure'
}

export const loadFeaturedCourses = createAction(CoursesActionsTypes.LoadFeaturedCourses);
export const loadFeaturedCoursesSuccess = createAction(
    CoursesActionsTypes.LoadFeaturedCoursesSuccess,
    props<{ payload: Course[] }>()
);
export const loadFeaturedCoursesFailure = createAction(
    CoursesActionsTypes.LoadFeaturedCoursesFailure,
    props<{ payload: APIError }>()
);

export const loadCourses = createAction(
    CoursesActionsTypes.LoadCourses,
    props<{
        payload?: {
            category?: string;
            tag?: string;
            pageSize?: number;
            page?: number;
            isFeatured?: boolean;
        };
    }>()
);
export const loadCoursesSuccess = createAction(
    CoursesActionsTypes.LoadCoursesSuccess,
    props<{ payload: { courses: Course[]; counters: any; coursesBottomReached: boolean } }>()
);
export const loadCoursesFailure = createAction(
    CoursesActionsTypes.LoadCoursesFailure,
    props<{ payload: APIError }>()
);

export const loadEnrolledCourses = createAction(CoursesActionsTypes.LoadEnrolledCourses);
export const loadEnrolledCoursesSuccess = createAction(
    CoursesActionsTypes.LoadEnrolledCoursesSuccess,
    props<{
        payload: { courses: Course[]; currentCourse: Course; nextVideoGuide: VideoGuide };
    }>()
);
export const loadEnrolledCoursesFailure = createAction(
    CoursesActionsTypes.LoadEnrolledCoursesFailure,
    props<{ payload: APIError }>()
);

export const loadCourse = createAction(CoursesActionsTypes.LoadCourse);
export const loadCourseById = createAction(
    CoursesActionsTypes.LoadCourseById,
    props<{ payload?: string }>()
);
export const loadCourseSuccess = createAction(
    CoursesActionsTypes.LoadCourseSuccess,
    props<{ payload: Course }>()
);
export const loadCourseFailure = createAction(
    CoursesActionsTypes.LoadCourseFailure,
    props<{ payload: APIError }>()
);

export const loadMasterCourse = createAction(CoursesActionsTypes.LoadMasterCourse);
export const loadMasterCourseSuccess = createAction(
    CoursesActionsTypes.LoadMasterCourseSuccess,
    props<{
        payload: {
            masterCourse: Course;
        };
    }>()
);
export const loadMasterCourseFailure = createAction(
    CoursesActionsTypes.LoadMasterCourseFailure,
    props<{ payload: APIError }>()
);

