import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Game } from '../../core/constants/game.enum';
import { Course, CourseType, CourseViewModel } from '../../models/course.model';
import { CoursesProgress } from '../../models/courses-progress.model';
import { GiveawayType } from '../../models/giveaway.model';
import { Redemption } from '../../models/redemption.model';
import { Section, SectionViewModel } from '../../models/section.model';
import { SeenVideosMap } from '../../models/seen-guide.model';
import { VideoGuide } from '../../models/video-guide.model';
import {
    CoursesState,
    coursesAdapter,
    enrolledCoursesAdapter,
    extendedCoursesAdapter
} from '../reducers/courses.reducer';
import { selectCoursesProgressMap, selectSeenVideosMap } from './meta.selectors';
import { selectCurrentGame } from './persist.selectors';
import { selectRedeemableItemsCountByType, selectRedeemedItemIds } from './referral.selectors';

function isGuideSeen(seenMap: SeenVideosMap, guide: VideoGuide) {
    return !!seenMap[guide.videoId];
}

export function mapCoursesMetadata(
    courses: Course[] | CourseViewModel[] | any,
    coursesProgressMap: CoursesProgress,
    seenVideosMap: SeenVideosMap,
    giveawayData: {
        redemptions: Redemption[];
        redeemableItemsByTypeGiveaways: { [key in GiveawayType]: string[] };
        redeemableItemsByType: { [key in GiveawayType]: number };
        redeemableItems: { [itemId: string]: string };
    },
    redeemedItemIds: string[]
): CourseViewModel[] {
    if (!courses) {
        return [];
    }

    if (!Array.isArray(courses)) {
        courses = [courses];
    }

    courses = courses.map(c => {
        // const itemAlreadyRedeemed = giveawayData.redemptions.some(red =>
        //     red.items.some((item: RedemptionItem) => item.itemId == c._id)
        // );
        const itemAlreadyRedeemed = redeemedItemIds.includes(c._id);
        const redeemableItems = giveawayData.redeemableItems;
        const redeemableCount = giveawayData?.redeemableItemsByType[GiveawayType.COURSES];
        const rdByType = giveawayData?.redeemableItemsByTypeGiveaways;
        const redeemableWithGivawayId =
            rdByType && rdByType[GiveawayType.COURSES] && rdByType[GiveawayType.COURSES][0];

        return {
            ...c,
            giveawayMetadata: {
                isRedeemed: itemAlreadyRedeemed,
                isRedeemable:
                    !itemAlreadyRedeemed && (redeemableCount > 0 || redeemableItems[c._id]),
                itemId: c._id,
                giveawayId: redeemableWithGivawayId || redeemableItems[c._id] || null
            }
        };
    });

    return courses.map(c => {
        const videoGuides =
            c.videoGuides ||
            (c.sections &&
                c.sections.reduce((vgs, s) => {
                    s.videoGuides.map(vg => vgs.push(vg));
                    return vgs;
                }, [])) ||
            [];

        const isStarted = videoGuides.some(vg => isGuideSeen(seenVideosMap, vg));
        const isFinished = videoGuides.every(vg => isGuideSeen(seenVideosMap, vg));
        const shouldGoToFirst = isFinished || !isStarted;
        const nextVideoGuide = shouldGoToFirst
            ? videoGuides[0]
            : videoGuides.find(vg => !isGuideSeen(seenVideosMap, vg));

        return {
            ...c,
            // sections: mapExtendedCourseSectionsMetadata(c.sections).sort(
            //     (a, b) => a.index - b.index
            // ),
            metadata: {
                progress: coursesProgressMap[c._id] || 0,
                duration: c.duration,
                isStarted,
                isFinished,
                nextVideoGuide
            }
        };
    });
}

export function mapExtendedCourseSectionsMetadata(
    sections: Section[] | SectionViewModel[] | any
): SectionViewModel[] {
    return sections.map(sctn => ({
        ...sctn,
        metadata: {
            duration: sctn.videoGuides.reduce((duration, vg) => {
                duration += vg.duration;
                return duration;
            }, 0),
            seenGuidesCount: sctn.videoGuides.filter(vg => vg?.metadata?.seen)?.length || 0
        }
    }));
}

const selectCoursesState = createFeatureSelector<CoursesState>('courses');
const selectCoursesSubstateState = createSelector(
    selectCoursesState,
    (state: CoursesState) => state.courses
);
const selectEnrolledCoursesSubstateState = createSelector(
    selectCoursesState,
    (state: CoursesState) => state.enrolledCourses
);
const selectExtendedCoursesSubstateState = createSelector(
    selectCoursesState,
    (state: CoursesState) => state.extendedCourses
);
// const selectCoursesState = createFeatureSelector<MemoizedSelector<GlobalState, any>>(
//     'courses'
// ).projector((state: GlobalState) => state.courses);

const coursesSelectors = coursesAdapter.getSelectors();
const enrolledCoursesSelectors = enrolledCoursesAdapter.getSelectors();
const extendedCoursesSelectors = extendedCoursesAdapter.getSelectors();

export const selectAllCourses = createSelector(
    selectCoursesSubstateState,
    coursesSelectors.selectAll
);

export const selectAllCurrentGameCourses = createSelector(
    selectAllCourses,
    selectCurrentGame,
    (courses: Course[], currentGame: Game) =>
        courses.filter(c => currentGame === null || c.game === currentGame)
);
export const selectAllEnrolledCourses = createSelector(
    selectEnrolledCoursesSubstateState,
    enrolledCoursesSelectors.selectAll
);
export const selectAllEnrolledCurrentGameCourses = createSelector(
    selectAllEnrolledCourses,
    selectCurrentGame,
    (courses: Course[], currentGame: Game) =>
        courses.filter(c => currentGame === null || c.game === currentGame)
);
export const selectAllExtendedCourses = createSelector(
    selectExtendedCoursesSubstateState,
    enrolledCoursesSelectors.selectAll
);
export const selectAllCoursesWithProgress = createSelector(
    selectAllCurrentGameCourses,
    selectCoursesProgressMap,
    selectSeenVideosMap,
    selectRedeemableItemsCountByType,
    selectRedeemedItemIds,
    mapCoursesMetadata
);
export const selectAllEnrolledCoursesWithProgress = createSelector(
    selectAllEnrolledCurrentGameCourses,
    selectCoursesProgressMap,
    selectSeenVideosMap,
    selectRedeemableItemsCountByType,
    selectRedeemedItemIds,
    mapCoursesMetadata
);
export const selectAllExtendedCoursesWithProgress = createSelector(
    selectAllExtendedCourses,
    selectCoursesProgressMap,
    selectSeenVideosMap,
    selectRedeemableItemsCountByType,
    selectRedeemedItemIds,
    mapCoursesMetadata
);
export const selectFeaturedCourses = createSelector(
    selectAllCoursesWithProgress,
    (courses: CourseViewModel[]) => {
        return courses.filter(c => c.isFeatured);
    }
);
export const selectHeroCourses = createSelector(
    selectAllCoursesWithProgress,
    (courses: CourseViewModel[]) => courses.filter(c => c.type === CourseType.HERO)
);
export const selectGeneralCourses = createSelector(
    selectAllCoursesWithProgress,
    (courses: CourseViewModel[]) => courses.filter(c => c.type === CourseType.GENERAL)
);
export const selectEnrolledCourses = createSelector(
    selectAllEnrolledCoursesWithProgress,
    (courses: CourseViewModel[]) => courses
);
export const selectCurrentCourseWithProgress = createSelector(
    selectCoursesState,
    selectCoursesProgressMap,
    selectSeenVideosMap,
    selectRedeemableItemsCountByType,
    selectRedeemedItemIds,
    (
        state: CoursesState,
        progressMap: CoursesProgress,
        seenVideosMap: SeenVideosMap,
        giveawayData: any,
        redeemedItemIds: string[]
    ) => {
        return mapCoursesMetadata(
            state.currentCourse,
            progressMap,
            seenVideosMap,
            giveawayData,
            redeemedItemIds
        );
    }
);
export const selectCurrentCourse = createSelector(
    selectCurrentCourseWithProgress,
    (courses: CourseViewModel[]) => courses[0]
);
export const selectCurrentCourseNextGuide = createSelector(
    selectCoursesState,
    (state: CoursesState) => state.currentCourseNextGuide
);
export const selectMasterCourse = createSelector(
    selectCoursesState,
    (state: CoursesState) => state.masterCourse
);
export const selectCoursesCounters = createSelector(selectCoursesState, (state: CoursesState) => {
    return state.coursesCounters.map(counter => {
        let c = { ...counter };
        if (c.category === 'total') {
            c.category = 'all';
        }

        return c;
    });
});

