import { Game } from '../core/constants/game.enum';
import { Player } from './player.model';
import { Section, SectionViewModel } from './section.model';
import { Tag } from './tag.model';
import { VideoGuide } from './video-guide.model';

export enum CoursesSource {
    SUBSCRIPTION = 'subscription',
    RECOMMENDATION = 'recommendation'
}

export enum CourseType {
    GENERAL = 'general',
    HERO = 'hero'
}

export enum CoursesSortingType {
    BY_RATING = 'by_rating',
    BY_PROGRESS = 'by_progress',
    MOST_POPULAR = 'most_popular'
}

export interface CourseMetadata {
    progress: number;
    duration: number;
    isStarted: boolean;
    isFinished: boolean;
    nextVideoGuide: VideoGuide;
}

export interface CourseGiveawayMetadata {
    isRedeemable: boolean;
    isRedeemed: boolean;
    itemId: string;
    giveawayId: string | null;
}

export interface Course {
    _id: string;
    name: string;
    description: string;
    isDeleted: boolean;
    slug: string;
    tag: Tag;
    nextCourse: Course;
    prevCourse: Course;
    index: number;
    type: string;
    categories: any[];
    isUpToDate: boolean;
    thumbnailPath: string;
    coverPath: string;
    sections: Section[] | SectionViewModel[];
    game: Game;
    aliases: string[];
    minRating: number;
    maxRating: number;
    duration: number;
    videoGuidesCount: number;
    courseRating: number;
    isFeatured: boolean;
    videoGuides: {
        _id: string;
        permalink: string;
        orderIndex: number;
        title: string;
        videoId: string;
        thumbnailPath: string;
    }[];
    // The properties below do not exist in the course schema
    count?: number;
    completed?: boolean;
    current?: Course;
    progress?: number;
    enrolledUsersCount?: number;
    players?: Player[];
    playersCount?: number;
    permalink: string;
    objectivesSummary: string[];
    expectedReleaseDate?: Date;
    comingSoon?: boolean;
}

export interface CourseViewModel extends Course {
    metadata: CourseMetadata;
    sections: SectionViewModel[];
    giveawayMetadata: CourseGiveawayMetadata;
}

export const courseTypeHeroLabels = {
    [Game.LEAGUE]: 'Champion',
    [Game.FORTNITE]: 'Player Analysis',
    [Game.OVERWATCH2]: 'Hero',
    [Game.DOTA]: 'Hero',
    [Game.VALORANT]: 'Agent',
    [Game.APEX]: 'Legend',
    [Game.HALO]: ''
};

