import { createAction, props } from '@ngrx/store';
import { Comment } from '../../models/comment.model';
import { EnrolledCourse } from '../../models/enrolled-course.model';
import { APIError } from '../../models/error.model';
import { RatedComment } from '../../models/rated-comment.model';
import { SeenVideo } from '../../models/seen-guide.model';
import { UserMetadata } from '../../models/user.model';

export enum GeneralMetaActionsTypes {
    LoadMetaDataEffects = '[Meta Effects] Load Meta Data Effects',
    LoadMetaDataEffectsSuccess = '[Users API] Load Meta Data Effects Success',
    LoadMetaDataEffectsFailure = '[Users API] Load Meta Data Effects Failure',

    LoadMetaDataDashboard = '[Dashboard Game Component] Load Meta Data',
    LoadMetaDataDashboardSuccess = '[Users API] Load Meta Data Success',
    LoadMetaDataDashboardFailure = '[Users API] Load Meta Data Failure',
}

export enum VideoGuidesMetaActionsTypes {
    SetVideoProgress = '[Video Guide Component] Set Video Progress',
    SetVideoProgressSuccess = '[Users API] Set Video Progress Success',
    SetVideoProgressFailure = '[Users API] Set Video Progress Failure',

    SetVideoSeen = '[Video Guide Component] Set Video Seen',
    SetVideoSeenSuccess = '[Users API] Set Video Seen Success',
    SetVideoSeenFailure = '[Users API] Set Video Seen Failure',

    SetVideoUnseen = '[Video Guide Component] Set Video Unseen',
    SetVideoUnseenSuccess = '[Users API] Set Video Unseen Success',
    SetVideoUnseenFailure = '[Users API] Set Video Unseen Failure'
}
// RecentVideoGuidesLoading = '[Video Guides Effects] Recent Video Guides Loading',
// RecentVideoGuidesLoadingFinished = '[Video Guides Effects] Recent Video Guides Loading Finished',

// SearchVideoGuidesLoading = '[Video Guides Effects] Search Video Guides Loading',
// SearchVideoGuidesLoadingFinished = '[Video Guides Effects] Search Video Guides Loading Finished'

export enum CoursesMetaActionsTypes {
    UpdateCourseEnrollment = '[Video Guide Component] Update Course Enrollment',
    UpdateCourseEnrollmentSuccess = '[Users API] Update Course Enrollment Success',
    UpdateCourseEnrollmentFailure = '[Users API] Update Course Enrollment Failure'
}

export enum CommentsMetaActionsTypes {
    LikeComment = '[Video Guide Component] Like Comment',
    LikeCommentSuccess = '[Users API] Like Comment Success',
    LikeCommentFailure = '[Users API] Like Comment Failure',

    DislikeComment = '[Video Guide Component] Dislike Comment',
    DislikeCommentSuccess = '[Users API] Dislike Comment Success',
    DislikeCommentFailure = '[Users API] Dislike Comment Failure',

    DeleteRatedComment = '[Video Guide Component] Delete Rated Comment',
    DeleteRatedCommentSuccess = '[Users API] Delete Rated Comment Success',
    DeleteRatedCommentFailure = '[Users API] Delete Rated Comment Failure'
}
// RecentVideoGuidesLoading = '[Video Guides Effects] Recent Video Guides Loading',
// RecentVideoGuidesLoadingFinished = '[Video Guides Effects] Recent Video Guides Loading Finished',

// SearchVideoGuidesLoading = '[Video Guides Effects] Search Video Guides Loading',
// SearchVideoGuidesLoadingFinished = '[Video Guides Effects] Search Video Guides Loading Finished'

/**
 * General Meta Actions
 */
export const loadMetaDataEffects = createAction(GeneralMetaActionsTypes.LoadMetaDataEffects);
export const loadMetaDataEffectsSuccess = createAction(
    GeneralMetaActionsTypes.LoadMetaDataEffectsSuccess,
    props<{ payload: UserMetadata }>()
);
export const loadMetaDataEffectsFailure = createAction(
    GeneralMetaActionsTypes.LoadMetaDataEffectsFailure,
    props<{ payload: APIError }>()
);

export const loadMetaDataDashboard = createAction(GeneralMetaActionsTypes.LoadMetaDataDashboard);
export const loadMetaDataDashboardSuccess = createAction(
    GeneralMetaActionsTypes.LoadMetaDataDashboardSuccess,
    props<{ payload: UserMetadata }>()
);
export const loadMetaDataDashboardFailure = createAction(
    GeneralMetaActionsTypes.LoadMetaDataDashboardFailure,
    props<{ payload: APIError }>()
);

/**
 * Video Guides Meta Actions
 */

export const setVideoProgress = createAction(
    VideoGuidesMetaActionsTypes.SetVideoProgress,
    props<{
        payload: {
            videoId: string;
            progress: number;
        };
    }>()
);
export const setVideoProgressSuccess = createAction(
    VideoGuidesMetaActionsTypes.SetVideoProgressSuccess,
    props<{ payload: { [videoId: string]: number } }>()
);
export const setVideoProgressFailure = createAction(
    VideoGuidesMetaActionsTypes.SetVideoProgressFailure,
    props<{ payload: APIError }>()
);

export const setVideoSeen = createAction(
    VideoGuidesMetaActionsTypes.SetVideoSeen,
    props<{ payload: { videoId: string } }>()
);
export const setVideoSeenSuccess = createAction(
    VideoGuidesMetaActionsTypes.SetVideoSeenSuccess,
    props<{ payload: SeenVideo }>()
);
export const setVideoSeenFailure = createAction(
    VideoGuidesMetaActionsTypes.SetVideoSeenFailure,
    props<{ payload: APIError }>()
);

export const setVideoUnseen = createAction(
    VideoGuidesMetaActionsTypes.SetVideoUnseen,
    props<{ payload: { videoId: string } }>()
);
export const setVideoUnseenSuccess = createAction(
    VideoGuidesMetaActionsTypes.SetVideoUnseenSuccess,
    props<{ payload: SeenVideo }>()
);
export const setVideoUnseenFailure = createAction(
    VideoGuidesMetaActionsTypes.SetVideoUnseenFailure,
    props<{ payload: APIError }>()
);

/**
 * Courses Meta Actions
 */
export const updateCourseEnrollment = createAction(
    CoursesMetaActionsTypes.UpdateCourseEnrollment,
    props<{ payload: { courseId: string; isCurrent: boolean; isDeleted: boolean } }>()
);
export const updateCourseEnrollmentSuccess = createAction(
    CoursesMetaActionsTypes.UpdateCourseEnrollmentSuccess,
    props<{ payload: EnrolledCourse }>()
);
export const updateCourseEnrollmentFailure = createAction(
    CoursesMetaActionsTypes.UpdateCourseEnrollmentFailure,
    props<{ payload: APIError }>()
);

/**
 * Comments Meta Actions
 */
export const likeComment = createAction(
    CommentsMetaActionsTypes.LikeComment,
    props<{ payload: { commentId: string } }>()
);
export const likeCommentSuccess = createAction(
    CommentsMetaActionsTypes.LikeCommentSuccess,
    props<{ payload: { ratedComment: RatedComment; comment: Comment } }>()
);
export const likeCommentFailure = createAction(
    CommentsMetaActionsTypes.LikeCommentFailure,
    props<{ payload: any }>()
);

export const dislikeComment = createAction(
    CommentsMetaActionsTypes.DislikeComment,
    props<{ payload: { commentId: string } }>()
);
export const dislikeCommentSuccess = createAction(
    CommentsMetaActionsTypes.DislikeCommentSuccess,
    props<{ payload: { ratedComment: RatedComment; comment: Comment } }>()
);
export const dislikeCommentFailure = createAction(
    CommentsMetaActionsTypes.DislikeCommentFailure,
    props<{ payload: any }>()
);

export const deleteRatedComment = createAction(
    CommentsMetaActionsTypes.DeleteRatedComment,
    props<{ payload: RatedComment }>()
);
export const deleteRatedCommentSuccess = createAction(
    CommentsMetaActionsTypes.DeleteRatedCommentSuccess,
    props<{ payload: { ratedComment: RatedComment; comment: Comment } }>()
);
export const deleteRatedCommentFailure = createAction(
    CommentsMetaActionsTypes.DeleteRatedCommentFailure,
    props<{ payload: APIError }>()
);
