import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SeenVideo, SeenVideosMap } from '../../models/seen-guide.model';
import { MetaState } from '../reducers/meta.reducer';
import {
    dislikedCommentsAdapter,
    enrolledCoursesAdapter,
    likedCommentsAdapter,
    playersSubscriptionsAdapter,
    seenVideosAdapter
} from '../reducers/meta.reducer';

const selectMetaState = createFeatureSelector<MetaState>('meta');

const selectSeenVideosSubstateState = createSelector(
    selectMetaState,
    (state: MetaState) => state.seenVideos
);
const selectPlayersSubscriptionsSubstateState = createSelector(
    selectMetaState,
    (state: MetaState) => state.playersSubscriptions
);
const selectLikedCommentsSubstateState = createSelector(
    selectMetaState,
    (state: MetaState) => state.likedComments
);
const selectDislikedCommentsSubstateState = createSelector(
    selectMetaState,
    (state: MetaState) => state.dislikedComments
);
const selectEnrolledCoursesSubstateState = createSelector(
    selectMetaState,
    (state: MetaState) => state.enrolledCourses
);

const seenVideosSelectors = seenVideosAdapter.getSelectors();
const playersSubscriptionsSelectors = playersSubscriptionsAdapter.getSelectors();
const likedCommentsSelectors = likedCommentsAdapter.getSelectors();
const dislikedCommentsSelectors = dislikedCommentsAdapter.getSelectors();
const enrolledCoursesSelectors = enrolledCoursesAdapter.getSelectors();

export const selectProgressVideosMap = createSelector(
    selectMetaState,
    (state: MetaState) => state.progressVideos
);
export const selectCoursesProgressMap = createSelector(
    selectMetaState,
    (state: MetaState) => state.coursesProgress
);
export const selectAllSeenVideos = createSelector(
    selectSeenVideosSubstateState,
    seenVideosSelectors.selectAll
);
export const selectAllPlayersSubscriptions = createSelector(
    selectPlayersSubscriptionsSubstateState,
    playersSubscriptionsSelectors.selectAll
);
export const selectAllLikedComments = createSelector(
    selectLikedCommentsSubstateState,
    likedCommentsSelectors.selectAll
);
export const selectAllDislikedComments = createSelector(
    selectDislikedCommentsSubstateState,
    dislikedCommentsSelectors.selectAll
);
export const selectAllEnrolledCourses = createSelector(
    selectEnrolledCoursesSubstateState,
    enrolledCoursesSelectors.selectAll
);
export const selectSeenVideosMap = createSelector(
    selectAllSeenVideos,
    (seenVideos: SeenVideo[]): SeenVideosMap =>
        seenVideos.reduce((acc, sv) => {
            acc[sv.videoId] = sv;
            return acc;
        }, {})
);
export const selectSeenVideoByVideoId = createSelector(
    selectAllSeenVideos,
    (seenVideos: SeenVideo[], videoId: string): SeenVideo | null =>
        seenVideos.find(sv => sv.videoId === videoId) || null
);
